<script setup>
import { usePage } from '@inertiajs/vue3'
import { watch } from 'vue'
import { toast } from 'vue3-toastify'
import AppHeader from '@/Shared/Layouts/AppHeader.vue'
import AppFooter from '@/Shared/Layouts/AppFooter.vue'
import AppLink from '@/Shared/Components/Links/AppLink.vue'
import AppLogo from '@/Shared/Components/AppLogo.vue'

const createToast = () => {
  const flashes = usePage().props.flash
  if (flashes.success != null) {
    toast.success(flashes.success, {
      autoClose: 3000,
      position: toast.POSITION.TOP_RIGHT,
    })
  }
  if (flashes.error != null) {
    toast.error(flashes.error, {
      autoClose: 3000,
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

watch(
  () => usePage().props.flash,
  () => {
    createToast()
  },
  {
    immediate: true,
  }
)
</script>

<template>
  <div class="flex flex-col min-h-screen">
    <AppHeader show-toolbar>
      <template #logo>
        <AppLogo />
      </template>

      <template #right-nav>
        <AppLink
          href="#"
          secondary
        >
          Report an issue
        </AppLink>
      </template>
    </AppHeader>
    <section class="h-min-[calc(100vh-274px)] max-w-6xl mx-auto p-4 lg:px-6 grid gap-8 pb-12 md:grid-cols-2">
      <div>
        <slot />
      </div>
    </section>
    <AppFooter />
  </div>
</template>
